'use client';

import { datadogLogs } from '@datadog/browser-logs';
import useLocalStorage from 'hooks/useLocalStorage';
import { useRouter } from 'navigation';
import { FC, ReactNode, createContext, useContext, useEffect } from 'react';
import { getProperty } from 'dot-prop';

export const onboarding_adhd_diagnosis_asd = '/onboarding/adhd/diagnosis-asd';
export const onboarding_adhd_diagnosis_asd_upload = '/onboarding/adhd/diagnosis-asd-upload';

const insurance_flow = [
  '/onboarding/insurance/options',
  '/onboarding/insurance/primary',
  '/onboarding/payment',
  '/onboarding/address',
];

const schedule_flow = [
  '/onboarding/adhd/schedule',
  '/onboarding/adhd/confirmed',
  '/medication/overview',
];

const flowMap: Record<string, any> = {
  appointment: {
    reschedule: ['/appointments/reschedule/confirmed'],
  },
  family: {
    add_child: [
      '/family',
      '/onboarding/adhd/add-child',
      '/onboarding/adhd/behaviors',
      '/onboarding/referral',
      '/onboarding/adhd/diagnosis-adhd',
      '/onboarding/adhd/provider',
      '/onboarding/adhd',
    ],
  },
  medication: [
    '/medication/choose-care',
    '/medication/overview',
    '/medication/appointment',
    '/medication/confirmed',
  ],
  pediatrician_referral: [
    '/referral',
    '/referral/clinic',
    '/referral/caregiver',
    '/referral/patient',
    '/referral/diagnosis',
    '/referral/complete',
  ],
  onboarding: {
    adhd: {
      intake: [
        '/onboarding/adhd',
        '/onboarding/adhd/parent-vanderbilt',
        '/onboarding/adhd/parent-vanderbilt/form',
        '/onboarding/adhd/teacher-vanderbilt',
        '/onboarding/adhd/teacher-vanderbilt/form',
        '/forms',
        ...insurance_flow,
        ...schedule_flow,
      ],
      pc: [
        '/onboarding/adhd',
        '/onboarding/adhd/diagnosis-adhd-upload',
        onboarding_adhd_diagnosis_asd,
        onboarding_adhd_diagnosis_asd_upload,
        ...insurance_flow,
        ...schedule_flow,
      ],
      referral_dx: ['/onboarding/adhd', '/forms', ...insurance_flow, ...schedule_flow],
      registration: [
        '/adhd',
        '/adhd/child-name',
        '/adhd/child-age',
        '/adhd/state',
        '/adhd/referral-source',
        '/adhd/eligible-success',
        '/auth/signup',
        '/auth/verify-request',
      ],
      self_referral: [
        '/onboarding/adhd/add-child',
        '/onboarding/adhd/behaviors',
        '/onboarding/referral',
        '/onboarding/adhd/diagnosis-adhd',
        '/onboarding/adhd/provider',
        '/onboarding/adhd',
      ],
    },
    forms: ['/forms', '/dashboard'],
  },
};

type ContextProps = {
  navstate?: any | null;
  setFlow: any;
  clearFlow: any;
  getIndex: any;
  getNumSteps: any;
  goToNextPage: any;
  goToPrevPage: any;
  goToHomePage: any;
  insertPage: any;
  insertPageAfter: any;
  replaceRoute: any;
};

const NavigationContext = createContext<ContextProps>({
  navstate: null,
  setFlow: () => {},
  clearFlow: () => {},
  getIndex: () => 0,
  getNumSteps: () => 0,
  goToNextPage: () => {},
  goToPrevPage: () => {},
  goToHomePage: () => {},
  insertPage: () => {},
  insertPageAfter: () => {},
  replaceRoute: () => {},
});

type Props = {
  children: ReactNode;
};

const NavigationProvider: FC<Props> = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const router = useRouter();
  const [navstate, setCurrentState, clearState] = useLocalStorage<Record<string, any>>(
    'clarity:navstate',
    {},
  );

  const setFlow = (key: string) => {
    setCurrentState({
      key,
      flow: getProperty(flowMap, key),
    });

    return getProperty(flowMap, key);
  };

  const insertPage = (next: string, idx: number) => {
    if (!navstate?.keys) {
      return;
    }

    navstate.flow.splice(idx, 0, next);
    setCurrentState(navstate);
  };

  const insertPageAfter = (next: string, route: string) => {
    if (!navstate?.keys) {
      return;
    }

    const idx = navstate.flow.indexOf(route);
    navstate.flow.splice(idx + 1, 0, next);
    setCurrentState(navstate);
  };

  const getIndex = () => {
    if (navstate?.flow) {
      const idx = navstate.flow.findIndex((path: string) => {
        return `${window.location.pathname}${window.location.search}`.endsWith(path);
      });

      return idx;
    }

    return -1;
  };

  const getNumSteps = () => {
    if (navstate?.flow) {
      return navstate.flow.length;
    }

    return 0;
  };

  const goToNextPage = (count?: number) => {
    if (!navstate?.flow) {
      router.refresh();
      return;
    }

    let idx = getIndex();
    idx += typeof count === 'number' ? count : 1;

    const nextPath = navstate.flow[idx];

    if (nextPath) {
      router.replace(nextPath);
    } else {
      datadogLogs.logger.error('No next path found', {
        flow: navstate.flow,
        idx,
      });
      router.replace('/');
    }
  };

  const goToPrevPage = (count?: number) => {
    if (!navstate?.flow) {
      router.refresh();
      return;
    }

    let idx = getIndex();
    idx -= typeof count === 'number' ? count : 1;

    const prevPath = navstate.flow[idx];

    if (prevPath) {
      router.replace(prevPath);
    } else {
      datadogLogs.logger.error('No prev path found', {
        flow: navstate.flow,
        idx,
      });
      router.replace('/');
    }
  };

  const replaceRoute = (path: string, replaceWith: string) => {
    if (navstate?.flow) {
      const idx = navstate.flow.indexOf(path);
      if (idx !== -1) {
        navstate.flow[idx] = replaceWith;
        setCurrentState(navstate);
      }
    }
  };

  const goToHomePage = () => {
    router.replace('/');
  };

  return (
    <NavigationContext.Provider
      value={{
        navstate,
        setFlow,
        clearFlow: clearState,
        getIndex,
        getNumSteps,
        goToNextPage,
        goToPrevPage,
        goToHomePage,
        insertPage,
        insertPageAfter,
        replaceRoute,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

// eslint-disable-next-line no-unused-vars
const useNavigationContext = (
  key?: string,
  addDynamicRoutes?: (context: ContextProps) => void,
  dependencies: any[] = [],
) => {
  const context = useContext(NavigationContext);

  useEffect(() => {
    if (key) {
      context.setFlow(key);

      if (addDynamicRoutes) {
        addDynamicRoutes(context);
      }
    }
  }, [key, ...dependencies]);

  return context;
};

export { NavigationContext, NavigationProvider, useNavigationContext };
