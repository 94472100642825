'use client';

import { datadogRum } from '@datadog/browser-rum';
import { UserWithPatientAddress } from 'database/models/users';
import { useSession } from 'next-auth/react';
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';
import { trpc } from 'trpc/client';

type ContextProps = {
  user?: UserWithPatientAddress | null;
  numAppointments?: number;
  numScreens?: number;
  utmParams?: any;
  features?: Record<string, any>;
  setUtmParams?: any;
  isZendeskWidgetOpen?: boolean;
  setIsZendeskWidgetOpen?: Dispatch<SetStateAction<boolean>>;
};

const AppContext = createContext<ContextProps>({});

type Props = {
  children: ReactNode;
};

const AppProvider: FC<Props> = ({ children }) => {
  const { status } = useSession();
  const [utmParams, setUtmParams] = useState<any>({});
  const [isZendeskWidgetOpen, setIsZendeskWidgetOpen] = useState<boolean>(false);
  const { data } = trpc.user.get.useQuery(undefined, {
    enabled: status === 'authenticated',
  });
  const {
    data: { features },
  } = trpc.features.list.useQuery(undefined, { initialData: { features: {} } });

  useEffect(() => {
    datadogRum.setUser({
      id: data?.user?.id,
    });
  }, [data?.user?.id]);

  return (
    <AppContext.Provider
      value={{
        user: data?.user,
        utmParams,
        features,
        setUtmParams,
        isZendeskWidgetOpen,
        setIsZendeskWidgetOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
